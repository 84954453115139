import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/addresscreate",
    name: "addresscreate",
    component: () => import("../views/AddressCreateView.vue"),
  },
  {
    path: "/addressedit",
    name: "addressedit",
    component: () => import("../views/AddressEditView.vue"),
  },
  {
    path: "/audios",
    name: "audios",
    component: () => import("../views/AudiosView.vue"),
  },
  {
    path: "/audiostransfer",
    name: "audiostransfer",
    component: () => import("../views/AudiosTransferView.vue"),
  },

  {
    path: "/calendar",
    name: "calendar",
    component: () => import("../views/CalendarView.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/DashboardView.vue"),
  },
  {
    path: "/externallinks",
    name: "externallinks",
    component: () => import("../views/ExternalLinksView.vue"),
  },
  {
    path: "/ibo",
    name: "ibo",
    component: () => import("../views/IBOView.vue"),
  },
  {
    path: "/ibocreate",
    name: "ibocreate",
    component: () => import("../views/IBOCreateView.vue"),
  },
  {
    path: "/iboedit",
    name: "iboedit",
    component: () => import("../views/IBOEditView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/LogoutView.vue"),
  },
  {
    path: "/onlineevents",
    name: "onlineevents",
    component: () => import("../views/OnlineEventsView.vue"),
  },
  {
    path: "/player",
    name: "player",
    component: () => import("../views/PlayerView.vue"),
  },
  {
    path: "/reports",
    name: "reports",
    component: () => import("../views/ReportsView.vue"),
  },
  {
    path: "/reportsnotpaidorders",
    name: "reportsnotpaidorders",
    component: () => import("../views/ReportsNotPaidOrdersView.vue"),
  },
  {
    path: "/reportslos",
    name: "reportslos",
    component: () => import("../views/ReportsLOSView.vue"),
  },
  {
    path: "/reportssalespoints",
    name: "reportssalespoints",
    component: () => import("../views/ReportsSalesPointsView.vue"),
  },
  {
    path: "/losiboedit",
    name: "losiboedit",
    component: () => import("../views/LOSIBOEditView.vue"),
  },
  {
    path: "/salesorders",
    name: "salesorders",
    component: () => import("../views/SalesOrdersView.vue"),
  },
  {
    path: "/salesorder",
    name: "salesorder",
    component: () => import("../views/SalesOrderView.vue"),
  },
  {
    path: "/salesorderdetail",
    name: "salesorderdetail",
    component: () => import("../views/SalesOrderDetailView.vue"),
  },
  {
    path: "/salesorderaddress",
    name: "salesorderaddress",
    component: () => import("../views/SalesOrderAddressView.vue"),
  },
  {
    path: "/salesordersummary",
    name: "salesordersummary",
    component: () => import("../views/SalesOrderSummaryView.vue"),
  },
  {
    path: "/salesorderccpayment",
    name: "salesorderccpayment",
    component: () => import("../views/SalesOrderCCPaymentView.vue"),
  },
  {
    path: "/salesordersqrccpayment",
    name: "salesordersqrccpayment",
    component: () => import("../views/SalesOrderSqrCCPaymentView.vue"),
  },
  {
    path: "/salesorderpaymentresult",
    name: "salesorderpaymentresult",
    component: () => import("../views/SalesOrderPaymentResultView.vue"),
  },
  {
    path: "/store",
    name: "store",
    component: () => import("../views/StoreView.vue"),
  },
  {
    path: "/tickets",
    name: "tickets",
    component: () => import("../views/TicketsView.vue"),
  },
  {
    path: "/mytickets",
    name: "mytickets",
    component: () => import("../views/MyTicketsView.vue"),
  },
  {
    path: "/ticketstransfer",
    name: "ticketstransfer",
    component: () => import("../views/TicketsTransferView.vue"),
  },
  {
    path: "/approveprovider",
    name: "approveprovider",
    component: () => import("../views/ApproveProviderView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
